.highlight {
	padding-left: 2px;
	padding-right: 2px;

	&.panel {
		box-shadow: none;
	}

	.ximg {
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;

		&.cover {
			background-size: cover;
		}

		&.smaller-width {
			width: 80%;
		}

		.ximg-content {
			border-radius: $border-radius;
		}
	}
}

.highlight-container {
	margin-left: 4px;
	margin-right: 4px;
}

.highlight-section {
	padding-left: $spacer-x;
	padding-right: $spacer-x;

	> header {
		clear: both;
		margin: $spacer-y 6px;
		border-bottom: 2px solid transparent;
		border-radius: 1px;
		flex-wrap: wrap;

		img {
			width: 15px;
			max-height: 18px;
			margin-right: 7px;
		}
	}

	a {
		@extend .secondary-link;
	}

	.highlight-nav {
		line-height: 0;
		display: flex;
		align-items: center;
		margin: 4px 12px 4px 0;

		.hl-nav-box {
			background: rgba(100, 110, 120, .3);
			width: 10px;
			height: 10px;
			cursor: pointer;
			transition: background .25s linear;
			box-shadow: 0px 1px 1px rgba(black, 0.2) inset;
			box-sizing: content-box;

			&:not(.active):hover {
				background: rgba(50, 60, 70, .4);
			}

			&.active {
				background: rgba(10, 15, 20, .6);
			}
		}
	}

	.highlight-container {
		white-space: nowrap;
		overflow: hidden;
	}

	.highlight-scroller {
		position: relative;
		transition: left .5s;
		transition-timing-function: ease-out;
		> * {
			float: none;
			position: relative;
			z-index: 0;
		}
	}

	.panel-product:hover .highlight-info .fake-link {
		text-decoration: underline;
	}

	.highlight-info {
		text-decoration: none;
		text-align: right;
		display: block;
		display: flex;

		> * {
			text-align: left;
		}

		.full-width-title {
			position: absolute;
			left: 0;
			right: 0;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.highlight-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		flex: 1;
		max-width: 100%;

		.highlight-subtitle {
			width: 0;
			max-width: 100%;
			display: block;
			color: $text-secondary-color;
			font-size: $font-size-small;
		}
	}

	.highlight-description {
		color: $text-secondary-color;
		font-size: $font-size-small;
		font-style: italic;
		height: 19px;
	}

	.product-list {
		// Select divs with any col class
		> div[class^='col-'] {
			padding: 0 5px;
		}
	}
}
